import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  sideBarHide: true,
};
export const layout = createAsyncThunk("layout", async (toggleValue) => {
  try {
    return toggleValue;
  } catch (error) {
    console.log({ error });
    return false;
  }
});

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  extraReducers: {
    [layout.fulfilled]: (state, action) => {
      state.sideBarHide = action.payload;
    },
  },
  reducers: {},
});

const { reducer } = layoutSlice;

export default reducer;
