import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./assets/scss/style.scss";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";

import Settings from "./components/settings";

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { sideBarHide } = useSelector((state) => state.layout);

  return (
    <Router>
      <div
        id={isLoggedIn ? "wrapper" : ""}
        className={!sideBarHide ? "mystyle" : ""}
      >
        <Routes>
          <Route path="/" element={<Settings />} />
          <Route path="/action-required/:status" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
