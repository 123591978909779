import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Navigate } from "react-router-dom";
import Header from "../Header";
import { logout } from "../../slices/auth";
import eventBus from "../../common/EventBus";
import EventBus from "../../common/EventBus";
import Footer from "../Footer";

const Layout = ({ children }) => {
  const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
  const { sideBarHide } = useSelector((state) => state.layout);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    eventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Header
        currentUser={currentUser}
        logOut={logOut}
        sideBarHide={sideBarHide}
      />

      <div>
        <Sidebar sideBarHide={sideBarHide} />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
