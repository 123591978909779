import { apiFetchWrapper } from "../utils/axiosApiWrapper";
import authHeader from "./auth-header";

const API_URL = "https://3.93.15.104/api/";

const getAdminBoard = ({ currentPage, totalPages }) => {
  if (currentPage === totalPages) return;
  return apiFetchWrapper.get(
    API_URL + `compliance/requests?page=${currentPage || 1}`,
    {
      headers: authHeader(),
    }
  );
};

const dashboardService = {
  getAdminBoard,
};

export default dashboardService;
