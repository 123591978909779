import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { layout } from "../slices/layout";
function Header({ currentUser, logOut, sideBarHide }) {
  const dispatch = useDispatch();

  return (
    <header>
      <nav className="navbar navbar-expand justify-content-between">
        <div className="navbar-nav mr-auto align-items-center">
          {!sideBarHide ? (
            <li>
              <a
                className="btn btn-danger-light ms-0 my-0 me-3"
                onClick={() => {
                  dispatch(layout(!sideBarHide));
                }}
                // data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <span className="bi-list"></span>
              </a>
            </li>
          ) : null}

          <li>
            <a
              className="navbar-brand pe-4"
              href="/action-required/action-required"
            >
              <img className="logo-dark" src={logo} alt="logo" />
            </a>
          </li>
          <li className="nav-item">
            <Link to={"/action-required/action-required"} className="nav-link">
              Home
            </Link>
          </li>

          {/* {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )} */}
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                Logout
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
