import axios from "axios";

const API_URL = "https://3.93.15.104/api/";

const login = async (username, password) => {
  try {
    const response = await axios.post(API_URL + "users/token/", {
      email: username,
      password,
    });
    if (response.data.access) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  } catch (error) {
    throw new Error("Fail to login");
  }
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
