import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./Layout";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <section className="listing-section">
        <div className="container-fluid">
          <header className="jumbotron">
            <h3>
              <strong>{currentUser.username}</strong> Profile
            </h3>
          </header>
          <p>
            <strong>Token:</strong> {currentUser?.access?.substring(0, 20)} ...{" "}
            {currentUser?.access.substr(currentUser?.access.length - 20)}
          </p>
          <p>
            <strong>Id:</strong> {currentUser.user_uuid}
          </p>
          <p>
            <strong>Email verified:</strong>{" "}
            {currentUser.email_verified && "true"}
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default Profile;
