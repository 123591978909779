import React from "react";
import { Link } from "react-router-dom";
import fbLogo from "../assets/images/fb.svg";
import linkedinLogo from "../assets/images/linkedin.svg";
import skypeLogo from "../assets/images/skype.svg";

function Footer() {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9">
            <ul>
              <li>
                <Link to="#">FAQ</Link>
              </li>
              <li>
                <Link to="#">About us</Link>
              </li>
              <li>
                <Link to="#">Terms of service</Link>
              </li>
              <li>
                <Link to="#">Terms of use</Link>
              </li>
              <li>
                <Link to="#">Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="social-icon justify-content-lg-end mt-3 mt-md-0">
              <li>
                <Link to="#">
                  <img src={fbLogo} alt="fb" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src={linkedinLogo} alt="tw" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src={skypeLogo} alt="skype" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
