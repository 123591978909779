import React, { useState } from "react";
import Layout from "./Layout";
import help from "../assets/images/help.svg";
import starFill from "../assets/images/star.svg";
import star from "../assets/images/star-light.svg";
import rate from "../assets/images/rate.png";
import Close from "../assets/images/close.svg";

function Settings() {
  const [emailCheck, setEmailCheck] = useState({});
  return (
    <Layout>
      <section className="listing-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <h2>Settings</h2>
            <p className="pb-3">
              Choose if you would like to receive a notification email
              immediately after an employee submits their information, or if you
              would like to receive the information at a given interval.
            </p>
            <h2>Email Notification</h2>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th>Email Notification</th> */}
                    <th>Intermediate notification</th>
                    <th>Daily Summary</th>
                    <th>Weekly Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td></td> */}
                    <td>
                      <div className="switch-box">
                        <label className="cswitch">
                          <input
                            className="cswitch-input"
                            type="checkbox"
                            checked={emailCheck?.notification}
                            onChange={(e) => {
                              setEmailCheck({ notification: e.target.checked });
                            }}
                          />
                          <span className="cswitch-trigger wrapper"></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="switch-box">
                        <label className="cswitch">
                          <input
                            className="cswitch-input"
                            type="checkbox"
                            checked={emailCheck?.dailySummary}
                            onChange={(e) => {
                              setEmailCheck({ dailySummary: e.target.checked });
                            }}
                          />
                          <span className="cswitch-trigger wrapper"></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="switch-box">
                        <label className="cswitch">
                          <input
                            className="cswitch-input"
                            type="checkbox"
                            checked={emailCheck?.weeklySummary}
                            onChange={(e) => {
                              setEmailCheck({
                                weeklySummary: e.target.checked,
                              });
                            }}
                          />
                          <span className="cswitch-trigger wrapper"></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form className="search-filter">
              <div className="row mt-4 mb-2">
                <div className="col-sm-6 col-md-4">
                  <div className="form-group position-relative mb-3">
                    <label className="form-label">Delegate Access</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                    />
                    <img className="bi-close" src={Close} alt="close" />
                    <img className="bi-search" src={help} alt="help" />
                  </div>
                  <button type="submit" className="btn btn-danger mt-2">
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <hr className="mt-5" />
            <div className="text-end">
              <button href="#" className="btn btn-outline-danger me-3">
                {" "}
                Cancel{" "}
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="btn btn-danger"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center p-4 m-1">
              <img src={rate} alt="rate" />
              <h3 className="mt-4">Your information has been received</h3>
              <p>
                You will soon receive an email with further information
                regarding your A1 application.'
              </p>

              <hr />
              <h3>Please rate your experience with this app.</h3>

              <div className="rating my-4">
                <img className="mx-1" width="75" src={starFill} alt="star" />
                <img className="mx-1" width="75" src={starFill} alt="star" />
                <img className="mx-1" width="75" src={starFill} alt="star" />
                <img className="mx-1" width="75" src={star} alt="star" />
                <img className="mx-1" width="75" src={star} alt="star" />
              </div>

              <hr />

              <h3 className="mb-3 pb-1">Write a review</h3>
              <form>
                <div className="mb-4">
                  <textarea
                    className="form-control"
                    placeholder="Star writing here"
                    rows="5"
                  ></textarea>
                </div>

                <button
                  type="button"
                  className="btn btn-danger px-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Settings;
