import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashBoardService from "../services/dashboard.service";
import { setMessage } from "./message";

const initialState = {
  data: [],
  loading: false,
  error: null,
  totalPages: 1,
  currentPage: 1,
};
export const dashboard = createAsyncThunk(
  "dashboard",
  async ({ status, totalPages, currentPage }, thunkAPI) => {
    try {
      const { data } = await dashBoardService.getAdminBoard({
        totalPages,
        currentPage,
      });
      return {
        dashboard: data.results,
        totalPages: Math.ceil(data.count / 20),
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [dashboard.fulfilled]: (state, action) => {
      state.data = action.payload.dashboard;
      state.totalPages = action.payload.totalPages;
    },
    [dashboard.rejected]: (state, action) => {
      state.data = [];
    },
  },
  reducers: {
    setCurrentPage: (state, action) => {
      return { ...state, currentPage: action.payload.currentPage };
    },
    clearData: () => {
      return { data: [] };
    },
  },
});

const { reducer, actions } = dashboardSlice;

export const { setCurrentPage, clearData } = actions;
export default reducer;
