import axios from "axios";
import authHeader from "../services/auth-header";
async function handleGetMethod(url) {
  return await fetch(url, {
    method: "GET",
    headers: authHeader(),
  });
}
async function handleResponse(response, callBack, url) {
  const text = await response?.text();
  const data = text && JSON.parse(text);
  let isError = false;
  let errorMesssage = "";
  let responseMessage = "";

  if (!response.ok) {
    if (response.status === 401) {
      const updatedData = await refreshAccessToken(callBack, url);

      return { data, error: isError, message: errorMesssage, ...updatedData };
    }
    isError = true;
    errorMesssage = data?.fields?.message?.length
      ? Array.isArray(data.fields.message)
        ? data?.fields?.message[0]
        : data?.fields?.message
      : responseMessage;
  } else {
    return { data, error: isError, message: errorMesssage };
  }
}

async function get(url, isCustom) {
  const response = await handleGetMethod(
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`
  );

  return handleResponse(
    response,
    handleGetMethod,
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`
  );
}

async function post(url, body, isCustom) {
  const requestHeader = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`,
    requestHeader
  );
  return handleResponse(response);
}

async function put(url, body, isCustom, isPayloadBinary) {
  const bodyPayload = isPayloadBinary
    ? await body.arrayBuffer()
    : JSON.stringify(body);
  const bodyHeaders = isPayloadBinary ? {} : { ...authHeader() };
  bodyHeaders["Content-Type"] = "image/jpeg";
  const response = await fetch(
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`,
    {
      method: "PUT",
      headers: bodyHeaders,
      body: bodyPayload,
    }
  );
  return handleResponse(response);
}

async function patch(url, body, isCustom) {
  const response = await fetch(
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: JSON.stringify(body),
    }
  );
  return handleResponse(response);
}

async function _delete(url, isCustom) {
  const response = await fetch(
    isCustom ? url : `${process.env.REACT_APP_API_URL}${url}`,
    {
      method: "DELETE",
      headers: authHeader(),
    }
  );
  return handleResponse(response);
}

const refreshAccessToken = async (callBack, url) => {
  const user = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}users/token/refresh/`,
      {
        refresh: user.refresh,
      }
    );
    if (response.data?.access) {
      const updatedUser = {
        ...user,
        access: response?.data?.access,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      const responseData = await callBack(url);
      return handleResponse(responseData);
    }
  } catch (error) {
    console.error("Token refresh failed:", error?.message);
    localStorage.removeItem("user");
    window.location.href = "/login";
  }
};
export const apiFetchWrapper = {
  get,
  post,
  put,
  patch,
  delete: _delete,
};
