import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { dashboard, setCurrentPage } from "../slices/dashboard";
import { Link } from "react-router-dom";
import { countryList } from "../common/constants";
import Layout from "./Layout";

import download from "../assets/images/download.svg";
const Home = () => {
  const STATUS = useMemo(() => {
    return {
      "completed-applications": "PROCESSED",
      "action-required": "ACTION_REQUIRED",
    };
  }, []);
  const {
    data: content,
    currentPage,
    totalPages,
  } = useSelector((state) => state.dashboard);

  const [filterResult, setFilterResult] = useState([]);

  const dispatch = useDispatch();
  const { status } = useParams();

  const filterContent = (search) => {
    const list = Object.keys(countryList).filter((key) =>
      countryList[key].toLowerCase().includes(search.toLowerCase())
    );

    const filterResult = content.filter(
      (item) =>
        list.includes(item.home_country) && item.status === STATUS[status]
    );
    setFilterResult(filterResult);
  };
  const handleSearch = (e) => {
    filterContent(e.target.value);
  };
  useEffect(() => {
    dispatch(dashboard({ status: STATUS[status], currentPage }));
  }, [currentPage, STATUS, dispatch, status]);

  useEffect(() => {
    if (content) {
      const filter = content.filter((item) => item.status === STATUS[status]);
      setFilterResult(filter);
    }
  }, [status, STATUS, content]);

  const PaginatedDataComponent = ({ data }) => {
    return <>{data.map((item) => renderRow(item))}</>;
  };

  const renderRow = (data) => {
    const date = new Date(data?.created_at);
    return (
      <tr key={data.uuid}>
        <td>{data.uuid}</td>
        <td>{countryList[data.home_country]}</td>
        <td>{date.toLocaleDateString("en-GB")}</td>
        <td>
          <Link href="#" className="text-danger">
            {data.compliance_type}
          </Link>
        </td>
        <td>
          <Link href="#" className="text-danger">
            Information
          </Link>
        </td>
        <td className="text-center">
          <a
            href={data.reference_details.file_url}
            target="_blank"
            rel="noreferrer"
          >
            <img src={download} alt="download" />
          </a>
        </td>
      </tr>
    );
  };

  return (
    <Layout>
      <main>
        <section className="listing-section">
          <div className="container-fluid">
            <div className="col-md-12">
              <h2>
                {status === "completed-applications"
                  ? "Completed Applications"
                  : "Action Required"}
              </h2>
              <p>
                {status !== "completed-applications" ? (
                  <>
                    Below is a list of compliance applications which are ready
                    for your review. Please download the applications and filing
                    information.
                    <br />
                    <br /> Should you have any questions, please contact us at{" "}
                  </>
                ) : (
                  <>
                    Below is a list of A1 social security applications which
                    have been filed. We have also included the relevant filing
                    information which were issued along with a copy of the A1
                    application.
                    <br />
                    <br />
                    If you have any questions, please contact us at{" "}
                  </>
                )}
                <Link
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  info@cibt.com
                </Link>
                .
              </p>
              <form className="search-filter">
                <div className="row mt-4 mb-2">
                  <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-group position-relative mb-3">
                      <label className="form-label">Home Country</label>
                      <input
                        onChange={(e) => handleSearch(e)}
                        type="email"
                        className="form-control"
                        placeholder="Search here"
                      />
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-group position-relative mb-3">
                      <label className="form-label">Host Country</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Search here"
                      />
                      <i className="bi bi-search"></i>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Case ID</th>
                      <th>Home Country</th>
                      <th>Date Submitted</th>
                      <th>Application Type</th>
                      <th>Filling Information</th>
                      <th className="text-center">Download A1 application</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {filterResult.length ? (
                      filterResult.map((item) => renderRow(item))
                    ) : (
                      <div>No record found!</div>
                    )} */}
                    {filterResult.length ? (
                      <PaginatedDataComponent
                        data={filterResult}
                        currentPage={currentPage}
                        totalPages={totalPages}
                      />
                    ) : (
                      <div>No record found!</div>
                    )}
                  </tbody>
                </table>
              </div>
              <hr className="mt-5" />
              <div className="text-end">
                <button
                  onClick={() =>
                    dispatch(setCurrentPage({ currentPage: currentPage - 1 }))
                  }
                  className="btn btn-outline-danger me-2"
                  disabled={currentPage === 1}
                >
                  {" "}
                  Previous{" "}
                </button>

                <button
                  className="btn btn-danger"
                  disabled={currentPage === totalPages}
                  onClick={() =>
                    dispatch(setCurrentPage({ currentPage: currentPage + 1 }))
                  }
                >
                  Next{" "}
                </button>
                <span className="ms-2">
                  {currentPage}/{totalPages}
                </span>
              </div>
            </div>
          </div>{" "}
          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <h3>Dear Sir/ Madam</h3>
                  <p>
                    This is a quick message to let you know there are some
                    actions required with respect to mobile employees. Please
                    click on
                    <u> this link</u> to access CIBT’s A1 app. It includes
                    details of A1 applications to be completed along with filing
                    information.
                  </p>
                  <p>
                    Should you have any questions, please contact
                    <a href="mailto:info@cibt.com"> info@cibt.com</a>.
                  </p>
                  <div className="text-end mt-250">
                    <button
                      type="button"
                      className="btn btn-danger px-5"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Home;
