import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import actionRequiredLight from "../assets/images/action-light.svg";
import completedLight from "../assets/images/completed-light.svg";

import settingLight from "../assets/images/setting-light.svg";

import actionSelecetd from "../assets/images/action.svg";
import completedSelected from "../assets/images/completed.svg";
import settingSelected from "../assets/images/setting.svg";
import { layout } from "../slices/layout";
import { useDispatch } from "react-redux";

function Sidebar(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const routes = [
    {
      name: "Action Required",
      key: "/action-required/action-required",
      icon: actionRequiredLight,
      selecetdIcon: actionSelecetd,
      link: "/action-required/action-required",
    },
    {
      name: "Completed Applications",
      key: "/action-required/completed-applications",
      icon: completedLight,
      selecetdIcon: completedSelected,
      link: "/action-required/completed-applications",
    },
    {
      name: "Settings",
      key: "/settings",
      icon: settingLight,
      selecetdIcon: settingSelected,
      link: "/settings",
    },
  ];
  return (
    <aside className="sidebar">
      <div
        className={`${
          props.sideBarHide ? "sidebar-show" : "sidebar-hidden"
        } offcanvas offcanvas-start`}
        tabindex="-1"
        // id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-body">
          <button
            className="btn btn-danger-light"
            onClick={() => {
              dispatch(layout(!props.sideBarHide));
            }}
            // aria-controls="offcanvasExample"
          >
            <span className="bi-list"></span>
          </button>
          <ul className="sidebar-nav">
            {routes.map((route, key) => {
              return (
                <li
                  className={route.key === pathname ? "active" : ""}
                  key={route.key}
                >
                  <NavLink to={route.link}>
                    <a href="action-required.html">
                      <img
                        src={
                          route.key === pathname
                            ? route.selecetdIcon
                            : route.icon
                        }
                        alt="action"
                      />
                      {route.name}
                    </a>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
