import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import dashboardReduce from "./slices/dashboard";
import layoutReduce from "./slices/layout";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  dashboard: dashboardReduce,
  layout: layoutReduce,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
